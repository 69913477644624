import React from 'react'
import './MobilityContent.scss'
import CallToActionBanner from '../../CallToActionBanner/CallToActionBanner'
import * as Fa from 'react-icons/fa'

export class MobilityContent extends React.Component {
  render() {
    return (
      <div className="mobility-content">
        <div className="split light no-padding">
          <section className="padding-large">
            <div className="row">
              <div className="col-md-6">
                <h3>Internet of Things</h3>
                <div className="service-row">
                  <div className="text-wrap">
                    <div className="title">Edge Computing</div>
                    <p>Perform tasks and deploy containers on IoT devices.</p>
                    <div className="key-tools">
                      <span>Key Tech & Partners:</span> AWS Greengrass, Azure
                      IoT Edge
                    </div>
                  </div>
                </div>
                <div className="service-row">
                  <div className="text-wrap">
                    <div className="title">IoT Platform Development</div>
                    <p>A resilient, performant backbone for your IoT system.</p>
                    <div className="key-tools">
                      <span>Key Tech & Partners:</span> Kubernetes, Amazon ECS
                    </div>
                  </div>
                </div>
                <div className="service-row">
                  <div className="text-wrap">
                    <div className="title">IoT Communication</div>
                    <p>
                      Use Bluetooth, NFC and more to enable cross-device
                      communication - for instance, accessing your IoT device
                      from a mobile app.
                    </p>
                    <div className="key-tools">
                      <span>Key Tech & Partners:</span> Bluetooth, BLE
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <img src="/iot_illustration.svg" />
              </div>
            </div>
          </section>
        </div>
        <section className="padding-large">
          <div className="row">
            <div className="col-md-6 col-md-push-6">
              <h3>VR Platform Development</h3>
              <div className="service-row">
                <div className="text-wrap">
                  <div className="title">VR-Optimized APIs</div>
                  <p>
                    Modern, performant Websocket-based APIs for your VR
                    application - built to be compatible with leading game
                    engines used in VR development.
                  </p>
                  <div className="key-tools">
                    <span>Key Tech & Partners:</span> NGINX, Docker
                  </div>
                </div>
              </div>
              <div className="service-row">
                <div className="text-wrap">
                  <div className="title">
                    Autoscaling + Healing Game Servers
                  </div>
                  <p>
                    Scale up or down to meet demand with resilience and failover
                    built in. Keep your user experience consistent in the event
                    of a regional failure.
                  </p>
                  <div className="key-tools">
                    <span>Key Tech & Partners:</span> Kubernetes, Amazon ECS
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-md-pull-6">
              <img src="/vr_illustration.svg" />
            </div>
          </div>
        </section>
        <section className="padding-large">
          <div className="row">
            <div className="col-md-6">
              <h3>Mobile Application Development</h3>
              <div className="service-row">
                <div className="text-wrap">
                  <div className="title">Hybrid-Native Mobile Apps</div>
                  <p>
                    Build for both Android and iOS platforms without sacrificing
                    performance.
                  </p>
                  <div className="key-tools">
                    <span>Key Tech & Partners:</span> React Native, Nativescript
                  </div>
                </div>
              </div>
              <div className="service-row">
                <div className="text-wrap">
                  <div className="title">Cross Platform App Development</div>
                  <p>
                    Fully cross-platform applications built with web
                    technologies.
                  </p>
                  <div className="key-tools">
                    <span>Key Tech & Partners:</span> Ionic Framework, Cordova
                  </div>
                </div>
              </div>
              <div className="service-row">
                <div className="text-wrap">
                  <div className="title">Native App Development</div>
                  <p>
                    Fully-native applications for when performance is the top
                    priority.
                  </p>
                  <div className="key-tools">
                    <span>Key Tech & Partners:</span> Kubernetes, Amazon ECS
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <img src="/mobile_illustration.svg" />
            </div>
          </div>
        </section>
        <div className="split pattern-alt">
          <section className="padding-large">
            <div className="row pwa">
              <div className="col-sm-6 col-md-push-6">
                <h3>Progressive Web Apps</h3>
                <div className="service-row">
                  <div className="text-wrap">
                    <div className="title">
                      Harness the newest web technologies to build engaging
                      mobile experiences.
                    </div>
                    <p>
                      Through the use of Service Workers, Accelerated Mobile
                      Pages (AMP), and other standards, we can build
                      applications that your users keep coming back to.
                    </p>
                    <div className="checkmark-list">
                      <div className="item">
                        <Fa.FaRegCheckSquare />
                        <div>Increased user engagement</div>
                      </div>
                      <div className="item">
                        <Fa.FaRegCheckSquare />
                        <div>Can be saved to user's home screen</div>
                      </div>
                      <div className="item">
                        <Fa.FaRegCheckSquare />
                        <div>Much improved conversions</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-sm-6 col-md-pull-6">
                <img src="/puppo_screen.png" />
              </div>
            </div>
          </section>
        </div>
        <CallToActionBanner route={'insights'} linkText={'See Case Studies'} />
      </div>
    )
  }
}

export default MobilityContent
