import React from 'react'
import Helmet from 'react-helmet'
import config from '../utils/siteConfig'
import '../templates/base.scss'
import Layout from '../components/Layout/Layout'
import SEO from '../components/SEO'
import MobilityContent from '../components/Mobility/MobilityContent/MobilityContent'
import MobilityHero from '../components/Mobility/MobilityHero/MobilityHero'

const Mobility = ({ data }) => {
  const postNode = {
    title: `Mobility - ${config.siteTitle}`,
  }

  return (
    <Layout>
      <SEO postNode={postNode} pagePath="cloudsecops" customTitle />
      <Helmet>
        <title>{`CloudSecOps - ${config.siteTitle}`}</title>
      </Helmet>
      <MobilityHero />
      <MobilityContent />
    </Layout>
  )
}

export default Mobility
