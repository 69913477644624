import React from 'react'
import './MobilityHero.scss'

export class MobilityHero extends React.Component {
  render() {
    return (
      <div
        className="digital-page-hero"
        style={{ backgroundImage: 'url(/cloudsecopsbg.jpg)' }}
      >
        <div className="overlay" />
        <div className="inner-wrap">
          <div className="text">
            <h1>Mobile, IoT and VR</h1>
            <h2>
              Build the next generation of innovative systems with emerging
              technologies
            </h2>
          </div>
        </div>
      </div>
    )
  }
}

export default MobilityHero
